import {
    TextField,
    Stack,
    Divider,
    Modal,
    Card,
    CardContent,
} from '@mui/material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import useErrorHandler from '../../hooks/useErrorHandler';
import { server } from '../../utils/axios';
import Add from '@mui/icons-material/Add';
import CreateItem from './CreateItems';
import { EventEmitter } from 'events';
import OrderItem from './OrderItem';
import CircularProgress from '@mui/material/CircularProgress';

const itemEventListener = new EventEmitter();

const NewOrder = () => {
    const { orderId } = useParams();
    const isEditMode = Boolean(orderId);
    const { showSuccess } = useMessage();
    const { startLoading, endLoading, loaderState } = useLoader();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const [attributes, setAttributes] = useState([]);
    const { openModal, closeModal, modalState } = useModal();
    const [products, setProducts] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);
    const [editOrder, setEditOrder] = useState({});

    useEffect(() => {
        const handleItemAdd = data => setOrderItems([...orderItems, data]);
        const handleItemEdit = orderItem => {
            const index = orderItems.findIndex(
                item => item.id === orderItem.id
            );
            orderItems[index] = orderItem;

            setOrderItems([...orderItems]);
        };
        itemEventListener.on('add', handleItemAdd);
        itemEventListener.on('edit', handleItemEdit);

        return () => {
            itemEventListener.removeListener('add', handleItemAdd);
            itemEventListener.removeListener('edit', handleItemEdit);
        };
    }, [orderItems]);

    const handlers = useForm(
        useMemo(
            () => ({
                customer: { required: true },
                shopify_id: {
                    required: true,
                    validator: value => isNaN(value) && 'Should be a number',
                },
            }),
            []
        ),
        { Input: TextField }
    );

    const getOrder = useCallback(async () => {
        startLoading();
        try {
            const response = await server.get(`/orders/${orderId}`, {
                params: { expand: 'customer' },
            });
            const order = response.data;
            setEditOrder(order);

            setAttributes([]);
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [orderId, startLoading, endLoading, errorHandler]);

    const getProducts = useCallback(async () => {
        setProducts([]);

        try {
            const response = await server.get(`/products/`);
            const products = response.data;

            setProducts(products.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    const getOrderItems = useCallback(async () => {
        if (!isEditMode) return;
        setOrderItems([]);

        try {
            const response = await server.get(`/order-items/`, {
                params: { order: orderId, expand: 'product' },
            });
            const orderItems = response.data;

            setOrderItems(orderItems.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, isEditMode, orderId]);

    const onSubmit = response => {
        if (response.data) {
            showSuccess('Order saved successfully');
            navigate(-1);
        }
    };

    const openEditModal = function (orderItem) {
        setSelectedOrderItem(orderItem);
        openModal();
    };

    useEffect(() => {
        if (isEditMode) getOrder();
    }, [isEditMode, getOrder]);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    useEffect(() => {
        getOrderItems();
    }, [getOrderItems]);

    return (
        <Box
            bgcolor="background.paper"
            height="calc(100vh - 76px)"
            overflow="auto"
            display="flex"
            flexDirection="column"
            p={0}>
            <Typography variant="h5" fontWeight={600} color="primary" p={2}>
                {isEditMode ? (
                    <Grid container alignItems="center">
                        Edit Order{' '}
                        {loaderState ? (
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                        ) : editOrder.shopify_id ? (
                            `#${editOrder.shopify_id} `
                        ) : (
                            '(Manual Order)'
                        )}
                    </Grid>
                ) : (
                    `New Order`
                )}
            </Typography>
            <Divider variant="fullWidth" />
            {loaderState ? (
                <Loading message="Please wait, while your data is loading..." />
            ) : (
                <Box p={2}>
                    <Grid container spacing={2} sx={{ mb: 3, mt: 2 }}>
                        {/* Customer Information */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Customer Information
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            {editOrder.customer ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Name
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                editOrder
                                                                    .customer
                                                                    .name
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    {editOrder.customer
                                                        .email && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Email
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    editOrder
                                                                        .customer
                                                                        .email
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography>
                                                    No customer details
                                                    available.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Shipping Address */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Shipping Address
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            {editOrder.shipping_address ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Address
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                editOrder
                                                                    .shipping_address
                                                                    .address1
                                                            }
                                                            ,{' '}
                                                            {
                                                                editOrder
                                                                    .shipping_address
                                                                    .city
                                                            }
                                                            ,{' '}
                                                            {
                                                                editOrder
                                                                    .shipping_address
                                                                    .country
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    {editOrder.shipping_address
                                                        .address2 && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Address2
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .address2
                                                                }
                                                                ,{' '}
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .city
                                                                }
                                                                ,{' '}
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .country
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {editOrder.shipping_address
                                                        .phone && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Phone
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .phone
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {editOrder.shipping_address
                                                        .company && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Company
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .company
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {editOrder.shipping_address
                                                        .province && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Province
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    editOrder
                                                                        .shipping_address
                                                                        .province
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Postal Code
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                editOrder
                                                                    .shipping_address
                                                                    .zip
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Box m={5} ml={15}>
                                                    <Typography>
                                                        No shipping address
                                                        details available.
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Order Information */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Order Information
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            {editOrder ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Order ID
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                editOrder.shopify_id
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Order Date
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {new Date(
                                                                editOrder.created_on
                                                            ).toLocaleDateString()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Financial Status
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                editOrder.financial_status
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Total Price
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            $
                                                            {
                                                                editOrder.total_price
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Box m={5} ml={15}>
                                                    <Typography>
                                                        No order details
                                                        available.
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Form
                        handlers={handlers}
                        onSubmit={onSubmit}
                        action={isEditMode ? `/orders/${orderId}` : '/orders/'}
                        method={isEditMode ? 'patch' : 'post'}
                        axiosInstance={server}
                        onError={errorHandler}
                        final={values => ({
                            ...values,
                            attributes,
                        })}
                        style={{ flexGrow: 1, position: 'relative' }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            mb={4}
                            overflow="auto">
                            {isEditMode && (
                                <>
                                    {orderItems.map(orderItem => (
                                        <OrderItem
                                            key={orderItem.id}
                                            orderItem={orderItem}
                                            openEditModal={openEditModal}
                                            itemEventListener={
                                                itemEventListener
                                            }
                                        />
                                    ))}
                                    <Box textAlign="center" my={2}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<Add />}
                                            onClick={openModal}>
                                            Add Order Items
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Stack
                            direction="row"
                            p={2}
                            spacing={2}
                            sx={{
                                boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                                backgroundColor: 'background.paper',
                                zIndex: 1100,
                                position: 'fixed',
                                bottom: 0,
                                width: {
                                    xs: '100%',
                                    xm: `calc(100% - 280px)`,
                                },
                            }}>
                            <Submit>
                                {loader => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={Boolean(loader)}
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}
                                        endIcon={loader}>
                                        Save
                                    </Button>
                                )}
                            </Submit>
                            <Button
                                onClick={() => navigate(-1)}
                                sx={{
                                    bgcolor: 'background.default',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                }}>
                                Cancel
                            </Button>
                        </Stack>
                    </Form>
                </Box>
            )}
            {isEditMode && (
                <Modal
                    open={modalState}
                    onClose={closeModal}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <CreateItem
                        orderItem={selectedOrderItem}
                        closeModal={closeModal}
                        products={products}
                        orderId={orderId}
                        itemEventListener={itemEventListener}
                    />
                </Modal>
            )}
        </Box>
    );
};

export default NewOrder;
