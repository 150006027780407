import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import SearchBar from '../../components/SearchBar';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import { CreateOrder } from './CreateOrder';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        width: 100,
    },
    {
        field: 'shopify_id',
        headerName: 'Shopify Id',
        sortable: false,
        width: 200,
        valueGetter: params => params.row.shopify_id || 'N/A',
    },
    {
        field: 'customer',
        headerName: 'Customer',
        flex: 1,
        sortable: false,
        renderCell: params => (
            <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ color: 'primary.main' }}>
                {params.row.customer.name}
            </Typography>
        ),
    },
    {
        field: 'created_on',
        headerName: 'Created On',
        sortable: false,
        width: 200,
        valueGetter: params =>
            new Date(params.row.created_on).toLocaleDateString(),
    },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const { startLoading, endLoading, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();

    const getOrders = useCallback(async () => {
        startLoading();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
            expand: 'customer',
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/orders/`, { params });
            const orders = response.data;

            setRows(orders.results);
            setRowCount(orders.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [paginationModel, search, startLoading, endLoading, errorHandler]);

    useEffect(() => {
        getOrders();
    }, [getOrders]);

    // Handle Modal
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Container maxWidth="false">
            <Box pt={3}>
                <Typography variant="h4" fontWeight={500}>
                    Orders
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ wordSpacing: '2px' }}>
                    Manage your orders from this page
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                onRowClick={data => navigate(`/orders/edit/${data.row.id}/`)}
                disableSelectionOnClick
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search, handleOpen },
                }}
                checkboxSelection={false}
            />

            <CreateOrder
                handleOpen={handleOpen}
                handleClose={handleClose}
                open={open}
            />
        </Container>
    );
};

export function CustomToolbar({ setSearch, search, handleOpen }) {
    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems="center" width="100%" spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleOpen}>
                        Create Order
                    </Button>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
