import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import SearchBar from '../../components/SearchBar';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import EditIcon from '@mui/icons-material/Edit';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
    },
    {
        headerName: 'Action',
        sortable: false,
        width: 150,
        renderCell: params => (
            <>
                <IconButton
                    LinkComponent={NavLink}
                    to={`/products/edit/${params.row.id}/`}>
                    <EditIcon />
                </IconButton>
            </>
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        flex: 1,
        renderCell: params => (
            <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ color: 'primary.main' }}>
                {params.row.name}
            </Typography>
        ),
    },
    {
        field: 'folder',
        headerName: 'Folder',
        sortable: false,
        width: 100,
    },
    {
        field: 'shopify_id',
        headerName: 'Shopify ID',
        sortable: false,
        width: 150,
    },
    {
        field: 'auto_generate',
        headerName: 'Script Enabled',
        width: 100,
        sortable: false,
        renderCell: params =>
            params.row.auto_generate ? <CodeIcon /> : <CodeOffIcon />,
    },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { startLoading: start, endLoading: end, loaderState } = useLoader();
    const errorHandler = useErrorHandler();

    const getProducts = useCallback(async () => {
        start();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/products/`, { params });
            const products = response.data;

            setRows(products.results);
            setRowCount(products.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            end();
        }
    }, [paginationModel, search, start, end, errorHandler]);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    return (
        <Container maxWidth="false">
            <Box pt={3}>
                <Typography variant="h4" fontWeight={500}>
                    Products
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ wordSpacing: '2px' }}>
                    Manage your products from this page
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                disableSelectionOnClick
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search },
                }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
        </Container>
    );
};

export function CustomToolbar({ setSearch, search }) {
    const navigate = useNavigate();

    return (
        <GridToolbarContainer sx={{ pb: '24px' }}>
            <Grid container alignItems="center" width="100%" spacing={1}>
                <Grid item xs>
                    <SearchBar
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('new')}>
                        Create Product
                    </Button>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default Index;
