import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/FileUpload';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import ActionIcon from '../../components/ActionIcon';
import { useMessage } from '../../components/Header';
import Image from '../../components/Image';
import useErrorHandler from '../../hooks/useErrorHandler';
import { server } from '../../utils/axios';
import { env } from '../../utils/function';

const OrderItem = props => {
    const { orderItem, openEditModal, itemEventListener } = props;
    const { id: orderItemId, product, more_attributes, svg_name } = orderItem;
    const errorHandler = useErrorHandler();
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const { showSuccess, showError } = useMessage();
    const uploadInput = useRef();
    const serverUri = env('SERVER');
    const [hash, setHash] = useState(1324);

    const updateHash = () => setHash(Math.floor(Math.random() * 1000000));

    const selectFile = () => uploadInput.current.click();

    const handleFileChange = e => upload(e.target.files[0]);

    const download = async function () {
        setDownloading(true);
        try {
            const response = await server.get('/download/' + orderItemId, {
                responseType: 'blob',
            });

            const dispo = response.headers['content-disposition'];

            const fileName = dispo
                .split(';')
                .find(el => el.split('=')[0].trim() === 'filename')
                .split('=')[1]
                .slice(1, -1);

            console.log(fileName);

            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        setDownloading(false);
    };

    const upload = async function (file) {
        setUploading(true);
        try {
            const formData = new FormData();

            formData.append('file', file);
            formData.append('orderItem', orderItemId);

            const response = await server.post('/upload-svg/', formData);
            const new_svg_name = response.data.file_name;

            itemEventListener.emit('edit', {
                ...orderItem,
                svg_name: new_svg_name,
            });

            updateHash();

            showSuccess('File uploaded successfully');
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        setUploading(false);
    };

    const sendToLaser = async (laser, order_item) => {
        try {
            await server.post('/logs/laser-queue/', { laser, order_item });
            showSuccess(`Order sent to laser ${laser} successfully`);
        } catch (error) {
            errorHandler(error);
        }
    };

    const preview = () => {
        // Open a new tab with the SVG preview
        if (!svg_name) return showError('No SVG preview available');

        window.open(`${serverUri}/svg-generated/${svg_name}`, '_blank');
    };

    return (
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                border: '1px solid #d9d9d9',
                borderRadius: '5px',
                boxShadow: 'none',
            }}>
            <Box p={3}>
                <Grid container alignItems="start" spacing={3}>
                    <Grid item xs>
                        <Stack>
                            <Typography variant="h5">{product.name}</Typography>
                            <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={1}
                                maxWidth="500px"
                                alignItems="center"
                                mt={3}>
                                {Object.keys(more_attributes).map(attr => (
                                    <React.Fragment key={attr}>
                                        <Grid
                                            item
                                            display="flex"
                                            gap={2}
                                            xs={12}
                                            sm={6}>
                                            <Typography
                                                variant="body2"
                                                fontWeight={600}>
                                                {attr}
                                            </Typography>
                                            <Typography variant="body2">
                                                {more_attributes[attr]}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item>
                        {svg_name && (
                            <Image
                                onClick={preview}
                                height="150px"
                                src={`${serverUri}/svg-generated/${svg_name}?random-hash=${hash}`}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <ActionIcon
                            icon={
                                <VisibilityIcon
                                    fontSize="small"
                                    sx={{ color: 'primary.main' }}
                                />
                            }
                            title="Preview"
                            onClick={preview}
                            tooltipProps={{ placement: 'left' }}
                        />
                        <br />
                        <ActionIcon
                            icon={
                                <EditIcon
                                    fontSize="small"
                                    sx={{ color: 'primary.main' }}
                                />
                            }
                            title="Edit"
                            onClick={() => openEditModal(orderItem)}
                            tooltipProps={{ placement: 'left' }}
                        />
                        <br />
                        <ActionIcon
                            icon={
                                downloading ? (
                                    <CircularProgress size="18px" />
                                ) : (
                                    <DownloadIcon
                                        fontSize="small"
                                        sx={{ color: 'primary.main' }}
                                    />
                                )
                            }
                            title="Download SVG"
                            onClick={download}
                            tooltipProps={{ placement: 'left' }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box bgcolor="#f8f9fb" py={1.5} px={2}>
                <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    justifyContent="space-between"
                    gap={1}
                    alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                        Send SVG file to laser machine for precise cutting and
                        engraving.
                    </Typography>
                    <Stack
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent="flex-end"
                        gap={1}
                        alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ fontSize: 12 }}
                            startIcon={<PrecisionManufacturingIcon />}
                            onClick={e => {
                                e.stopPropagation();
                                sendToLaser(1, orderItemId);
                            }}>
                            Send to laser 1
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ fontSize: 12 }}
                            startIcon={<PrecisionManufacturingIcon />}
                            onClick={e => {
                                e.stopPropagation();
                                sendToLaser(2, orderItemId);
                            }}>
                            Send to laser 2
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ fontSize: 12 }}
                            disabled={uploading}
                            startIcon={
                                uploading ? (
                                    <CircularProgress size="18px" />
                                ) : (
                                    <UploadIcon />
                                )
                            }
                            onClick={selectFile}>
                            Upload SVG
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <input
                type="file"
                onChange={handleFileChange}
                ref={uploadInput}
                style={{ display: 'none' }}
            />
        </Card>
    );
};

export default OrderItem;
