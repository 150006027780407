import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';
import DataGrid from '../../components/DataGrid';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        width: 100,
    },
    {
        field: 'type',
        headerName: 'Type',
        sortable: false,
        width: 200,
    },
    {
        field: 'date_created',
        headerName: 'Created At',
        sortable: false,
        width: 200,
        valueGetter: params =>
            new Date(params.row.date_created).toLocaleDateString(),
    },
    {
        field: 'data',
        headerName: 'Data',
        sortable: false,
        flex: 1,
        valueGetter: params => JSON.stringify(params.row.data),
    },
];

const Index = () => {
    const [logs, setLogs] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { startLoading, endLoading, loaderState } = useLoader();
    const errorHandler = useErrorHandler();

    const getLogs = useCallback(async () => {
        startLoading();
        setLogs([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/logs/`, { params });
            const orders = response.data;

            setLogs(orders.results);
            setRowCount(orders.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [paginationModel, search, startLoading, endLoading, errorHandler]);

    useEffect(() => {
        getLogs();
    }, [getLogs]);

    return (
        <Container maxWidth="false">
            <Box pt={3}>
                <Typography variant="h4" fontWeight={500}>
                    Logs
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ wordSpacing: '2px' }}>
                    Manage your logs from this page
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
            </Box>
            <DataGrid
                rows={logs}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                autoHeight
                // onRowClick={data => navigate(`/logs/edit/${data.row.id}/`)}
                disableSelectionOnClick
                loading={loaderState}
                rowCount={rowCount}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: { setSearch, search },
                }}
                checkboxSelection={false}
                components={{
                    Footer: CustomToolbar,
                }}
            />
        </Container>
    );
};

export function CustomToolbar() {
    // const { setSearch, search } = props;
    // const navigate = useNavigate();

    return;
    // <GridToolbarContainer sx={{ pb: '24px' }}>
    //     <Grid container alignItems='center' width='100%' spacing={1}>
    //         <Grid item xs>
    //             <SearchBar
    //                 value={search}
    //                 onChange={e => setSearch(e.target.value)}
    //             />
    //         </Grid>
    //         <Grid item>
    //             <Button
    //                 variant='contained'
    //                 color='secondary'
    //                 onClick={() => navigate('new')}>
    //                 Create Log
    //             </Button>
    //         </Grid>
    //     </Grid>
    // </GridToolbarContainer>
}

export default Index;
