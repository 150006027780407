import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/';
import { server } from '../../utils/axios';

const financialStatuses = ['Paid', 'Unpaid'];

export function CreateOrder({ handleOpen, handleClose, open }) {
    // Form Data States
    const handlers = useForm(
        useMemo(
            () => ({
                name: { required: true },
                email: { required: true },
                address1: { required: true },
                address2: {},
                city: { required: true },
                country: { required: true },
                phone: { required: true },
                zip: { required: true },
                company: { required: true },
                status: { required: true },
                totalPrice: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    console.log(handlers.errors);

    const onSubmit = async () => {
        // Submit form logic here
        // Close the modal after submission
        console.log('onSubmit');
        // handleClose();
    };

    const handleSelectChange = event => {
        const { name, value } = event.target;

        handlers.setValues({ [name]: value });
    };

    function modifyDataForApi(values) {
        return {
            new_customer: {
                name: values.name,
                email: values.email,
            },
            shipping_address: {
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                country: values.country,
                phone: values.phone,
                zip: values.zip,
                company: values.company,
            },
            financial_status: values.status,
            total_price: values.totalPrice,
        };
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <Form
                handlers={handlers}
                action="orders/"
                method="POST"
                axiosInstance={server}
                onSubmit={onSubmit}
                final={modifyDataForApi}>
                <DialogTitle>
                    Create Order
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    {/* Customer Section */}
                    <Typography variant="h6">Customer Details</Typography>
                    <Input
                        fullWidth
                        placeholder="Name"
                        margin="normal"
                        name="name"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        placeholder="Email"
                        name="email"
                    />
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    {/* Shipping Section */}
                    <Typography variant="h6">Shipping Address</Typography>
                    <Input
                        fullWidth
                        margin="normal"
                        name="address1"
                        placeholder="Address1"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="address2"
                        placeholder="Address2"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="city"
                        placeholder="City"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="country"
                        placeholder="Country"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="phone"
                        placeholder="Phone"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="zip"
                        placeholder="Postal code"
                    />
                    <Input
                        fullWidth
                        margin="normal"
                        name="company"
                        placeholder="Company"
                    />
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    {/* Order Section */}
                    <Typography variant="h6" mb={2}>
                        Order Details
                    </Typography>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <Select
                            name="status"
                            value={handlers.values.status}
                            onChange={handleSelectChange}
                            displayEmpty
                            required>
                            <MenuItem value="" disabled>
                                Status
                            </MenuItem>
                            {financialStatuses.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Input
                        fullWidth
                        margin="normal"
                        name="totalPrice"
                        placeholder="Price"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
