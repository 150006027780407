import Webhook from '@mui/icons-material/Webhook';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TocIcon from '@mui/icons-material/Toc';

const MenuItems = [
    {
        name: 'Products',
        icon: <CategoryIcon fontSize="small" />,
        to: '/products',
    },
    {
        name: 'Orders',
        icon: <ShoppingCartIcon fontSize="small" />,
        to: '/orders',
    },
    {
        name: 'Logs',
        icon: <TocIcon fontSize="small" />,
        to: '/logs',
    },
    {
        name: 'Webhooks',
        icon: <Webhook fontSize="small" />,
        to: '/webhooks',
    },
    // {
    //     name: 'Master Data',
    //     icon: <InsightsOutlinedIcon fontSize='small' />,
    //     to: [
    //         {
    //             name: 'Goal',
    //             icon: <FlagCircleOutlinedIcon fontSize='small' />,
    //             to: '/master/goal',
    //         },
    //     ],
    // },
];

export default MenuItems;
