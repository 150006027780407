import axios from 'axios';
import { getCookie } from './cookies';
import { env } from './function';

const server = axios.create({
    baseURL: env('SERVER'),
    withCredentials: false,
});

server.interceptors.request.use(function (config) {
    const accessToken = getCookie('accessToken');
    if (!config.headers.Authorization)
        config.headers.Authorization = `Token ${accessToken}`;

    return config;
});

export { server };
