export function getObjectFromArray(arr, key = 'key', value = 'value') {
    const fullObj = {};

    for (const obj of arr) fullObj[obj[key]] = obj[value];

    return fullObj;
}

export function getArrayFromObject(obj, key = 'key', value = 'value') {
    const arr = [];

    for (const keyName in obj) {
        const valueName = obj[keyName];
        arr.push({ [key]: keyName, [value]: valueName });
    }

    return arr;
}
