import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './utils/axios';
import AuthorizeRouter from './router/AuthorizeRouter';
import Header from './components/Header';
import Products from './pages/Products';
import CreateProducts from './pages/Products/Create';
import Orders from './pages/Orders';
import ViewOrder from './pages/Orders/ViewOrder';
import Logs from './pages/Logs';
import Webhooks from './pages/Webhooks';

const App = () => {
    return (
        <Header>
            <Routes>
                <Route path="/" element={<AuthorizeRouter />}>
                    <Route path="/" element={<h2>Welcome</h2>} />
                    <Route path="/products">
                        <Route path="" element={<Products />} />
                        <Route path="new" element={<CreateProducts />} />
                        <Route
                            path="edit/:productId"
                            element={<CreateProducts />}
                        />
                    </Route>
                    <Route path="/orders">
                        <Route path="" element={<Orders />} />
                        <Route path="new" element={<ViewOrder />} />
                        <Route path="edit/:orderId" element={<ViewOrder />} />
                    </Route>
                    <Route path="/logs" element={<Logs />} />
                    <Route path="/webhooks" element={<Webhooks />} />
                </Route>
            </Routes>
        </Header>
    );
};

export default App;
