function env(name) {
    const nodeENV = process.env.NODE_ENV.toUpperCase();
    console.log(nodeENV);

    return (
        process.env[`REACT_APP_${nodeENV}_${name}`] ||
        process.env[`REACT_APP_${name}`]
    );
}

const getSession = name => sessionStorage[name];

const setSession = (name, value) => (sessionStorage[name] = value);

const deleteSession = name => sessionStorage.removeItem(name);

const isDefined = v => typeof v !== 'undefined';

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function escapeDanger(content) {
    const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim;

    if (regex.test(content)) return null;
    return { __html: content };
}

export {
    env,
    getSession,
    setSession,
    deleteSession,
    isDefined,
    isEmpty,
    escapeDanger,
};
